.connectBtn {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border: white 2px solid;
  background: none;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  transition-duration: 0.2s;
}
.connectBtn:hover {
  background: white;
  color: black;
}
.container {
  text-align: center;
}

.connectModalCard {
  border-radius: 8px;
  background: var(--dark);
  text-align: center;
  padding: 15px;
  z-index: 1;
}
.walletOption {
  background-color: #007951;
  width: 100%;
  height: 40px;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  transition-duration: 0.2s;
}
.walletOption:hover {
  background-color: #009a66;
}
.backBtn {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  padding-bottom: 10px;
}
.backBtn:hover {
  color: var(--primary);
}
.homeBtn {
  background: white;
  border: none;
  border-radius: 5px;
  width: 60px;
  margin-left: 14px;
  transition: 0.2s;
}
.homeBtn:hover {
  background: rgb(193, 193, 193);
}
.homeIcon {
  width: 25px;
}
