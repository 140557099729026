.punkCard {
  background-color: var(--lighter);
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.punkCardContent {
  margin-left: 30px;
  margin-right: 30px;
}

/* style={{marginLeft: "14px", backgroundColor: "var(--lighter)", padding: "0"}} */
.randomButton {
  margin-left: 14px;
  background-color: var(--lighter);
  padding-top: 0;
  padding-bottom: 0;
  width: 60px;
  border: none;
  border-radius: 5px;
  flex-shrink: 0;
}

.randomButton:hover {
  background-color: var(--lighterer);
}