@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --shadow: #0e1310;
  --dark: #18201b;
  --lighter: #2a3830;
  --lighterer: #3b4e44;
  --primary: #00b87b;
}

.App {
  background-color: #18201b;
  background-color: var(--dark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

@media (max-width: 450px) {
  h1 {
    font-size: 20px !important;
  }
}

h1 {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0px;
  margin-top: 0px;
}

button {
  cursor: pointer;
  transition: 0.2s;
}

h2 {
  font-size: 26px;
}

h3 {
  text-align: center;
  font-size: 17px;
  font-weight: 300;
  text-align: left;
}

a {
  color: #00b87b;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p {
  margin: 0;
}

li {
  margin-left: -10px;
  margin-bottom: 10px;
}

.linksContainer a {
  margin-left: 5px;
  margin-right: 5px;
}

.linksContainer a:hover {
  -webkit-filter: drop-shadow(0px 0px 4px #00ffaa);
          filter: drop-shadow(0px 0px 4px #00ffaa);
}

.switchNetworkBtn {
  background: none;
  border: 2px solid #b86500;
  max-width: 400px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  color: #b86500;
  font-size: 20px;
  font-weight: bold;
  transition-duration: 0.2s;
}
.switchNetworkBtn:hover {
  background: #b86500;
  color: white;
}

.toolTip {
  position: relative;
  display: inline-block;
}
.toolTip .toolTipText {
  visibility: hidden;
  background-color: white;
  color: black;
  text-align: center;
  font-size: 14px;
  padding: 5px 5px;
  border-radius: 6px;
  bottom: 100%;
  left: 50%;
  margin-left: -50%;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  position: absolute;
  z-index: 1;
  font-weight: 400;
}
.toolTip:hover .toolTipText {
  visibility: visible;
}

.GenericModal_fullscreenModal__2PReX {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
}

.GenericModal_modalContainer__1SH8_ {
  width: 90%;
  max-width: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 100px;
  z-index: 2;
  position: relative;
  padding: 40px;
}

.ConnectButton_connectBtn__17m_9 {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border: white 2px solid;
  background: none;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  transition-duration: 0.2s;
}
.ConnectButton_connectBtn__17m_9:hover {
  background: white;
  color: black;
}
.ConnectButton_container__2Ow1q {
  text-align: center;
}

.ConnectButton_connectModalCard__3dXe_ {
  border-radius: 8px;
  background: var(--dark);
  text-align: center;
  padding: 15px;
  z-index: 1;
}
.ConnectButton_walletOption__1X8ch {
  background-color: #007951;
  width: 100%;
  height: 40px;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  transition-duration: 0.2s;
}
.ConnectButton_walletOption__1X8ch:hover {
  background-color: #009a66;
}
.ConnectButton_backBtn__2tVtD {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  padding-bottom: 10px;
}
.ConnectButton_backBtn__2tVtD:hover {
  color: var(--primary);
}
.ConnectButton_homeBtn__3NxKi {
  background: white;
  border: none;
  border-radius: 5px;
  width: 60px;
  margin-left: 14px;
  transition: 0.2s;
}
.ConnectButton_homeBtn__3NxKi:hover {
  background: rgb(193, 193, 193);
}
.ConnectButton_homeIcon__3GbES {
  width: 25px;
}

.NeonText_neonHeadCont__3AzcV {
  text-align: center;
}
.NeonText_neonHeadImg__2Zc0Z {
  width: 100%;
  max-width: 750px;
}

.Punk_punk__2Sra1 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Punk_punk__2Sra1 img {
  width: 100%;
  border-radius: 5px;
}

.PunkDetail_atrribute__FzLVo {
  color: white;
  border: 1px white solid;
  border-radius: 4px;
  display: inline-block;
  border-radius: 7px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 7px;
  padding-top: 7px;
  margin-right: 6px;
  margin-bottom: 6px;
}
/* style={{display:"flex", flexWrap: "wrap", width: "350px", justifyContent: "left", marginLeft:"21px",marginTop:"5px",marginBottom:"25px"} */
.PunkDetail_attributesContainer__3lPFl {
  margin-top: 20px;
}

.PunkDetail_attributesGroup__X_PEd {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.ClaimButton_claimButton__EA_RU {
  background-color: var(--primary);
  width: 100%;
  height: 60px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.ClaimButton_claimButton__EA_RU:hover {
  background-color: #00d08b;
}

.ClaimButton_helpBtn__35yR2 {
  border-radius: 15px;
  height: 24px;
  width: 24px;
  color: black;
  background: white;
  border: none;
  font-weight: bold;
  font-size: 17px;
  margin-left: 20px;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.ClaimButton_toolTip__11OJL {
  position: relative;
  display: inline-block;
}
.ClaimButton_toolTip__11OJL .ClaimButton_toolTipText__1X9PH {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: black;
  text-align: center;
  font-size: 14px;
  padding: 5px 5px;
  border-radius: 6px;
  width: 240px;
  bottom: 100%;
  left: 50%;
  margin-left: -120px;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  position: absolute;
  z-index: 1;
  font-weight: 400;
}
.ClaimButton_toolTip__11OJL:hover .ClaimButton_toolTipText__1X9PH {
  visibility: visible;
}

.Search_searchContainer__29xXS {
  background-color: var(--lighter);
  height: 50px;
  display: flex;
  border-radius: 5px;
  flex-grow: 1;
}

.Search_searchContainer__29xXS input {
  height: 100%;
  border: none;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 17px;
  background-color: inherit;
  font-size: 24px;
  color: white;
  border-radius: inherit;
  width: 80%;
  z-index: 1;
}

.Search_searchContainer__29xXS input::-webkit-input-placeholder {
  color: #838383;
}

.Search_searchContainer__29xXS input:-ms-input-placeholder {
  color: #838383;
}

.Search_searchContainer__29xXS input::placeholder {
  color: #838383;
}

.Search_searchContainer__29xXS button  {
  border: none;
  background-color: inherit;
  border-radius: 5px;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
}

.Search_searchContainer__29xXS button:hover {
  background-color: var(--lighterer);
}

.Search_searchContainer__29xXS button img {
  height: 30px;
}
.AddressTypeTag_tag__1h4f3 {
  height: 18px;
  background: white;
  border: none;
  border-radius: 4px;
  color: var(--lighter);
  font-weight: bold;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 14px;
}

.AddressTypeTag_content__1fIEN {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 2px;
  margin-top: 2px;
}

/* style={{marginLeft: "auto", border: "none", "background": "none", cursor: "pointer", height: "18px"}} */
.PunkCardHeader_twitterButton__5MIV2 {
  margin-left: auto;
  border: none;
  background: none;
  height: 22px;
  margin-top: auto;
  margin-bottom: auto;
}

.PunkCardHeader_twitterButton__5MIV2:hover {
  -webkit-filter: drop-shadow(0px 0px 4px #00FFAA);
          filter: drop-shadow(0px 0px 4px #00FFAA);
}

.PunkCardHeader_twitterButton__5MIV2 img {
  height: 100%;
}
.PunkCard_punkCard__1vlF1 {
  background-color: var(--lighter);
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.PunkCard_punkCardContent__j2Ip5 {
  margin-left: 30px;
  margin-right: 30px;
}

/* style={{marginLeft: "14px", backgroundColor: "var(--lighter)", padding: "0"}} */
.PunkCard_randomButton__1ISRK {
  margin-left: 14px;
  background-color: var(--lighter);
  padding-top: 0;
  padding-bottom: 0;
  width: 60px;
  border: none;
  border-radius: 5px;
  flex-shrink: 0;
}

.PunkCard_randomButton__1ISRK:hover {
  background-color: var(--lighterer);
}
